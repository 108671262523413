import { createContext, useMemo, useState } from 'react';

interface IStoreGlobalContext {
  isLoading: boolean;
  setIsLoading: any;
  openEditProject?: boolean;
  defaultResultsProject?: number;
  setDefaultResultsProject?: any;
}

const StoreGlobalContext = createContext<IStoreGlobalContext>({
  isLoading: false,
  setIsLoading: null,
});

function GlobalStateProvider(props: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [defaultResultsProject, setDefaultResultsProject] = useState(undefined);
  const storeGlobal = useMemo(
    () => ({
      isLoading: isLoading,
      setIsLoading,
      defaultResultsProject: defaultResultsProject,
      setDefaultResultsProject,
    }),
    [isLoading, setIsLoading, defaultResultsProject, setDefaultResultsProject],
  );

  return <StoreGlobalContext.Provider value={storeGlobal}>{props.children}</StoreGlobalContext.Provider>;
}
export { StoreGlobalContext, GlobalStateProvider };
