// export routes on application

export const MENU_PARRENT = {
  HOME: '/home',
  PROJECT: '/projects',
  MODULES: '/modules',
  EQUIPMENT: '/equipment',
  REPORT: '/report-project',
  ANALYZE: '/data-analyze',
  ADMIN: '/admins',
  EDIT: 'edit',
};

export const ROUTES = {
  HOME: MENU_PARRENT.HOME,
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:id/:token',
  CHECK_TOKEN_RESET_PASSWORD: '/check-token-reset-password/:id/:token',
  PROJECT_LIST: MENU_PARRENT.PROJECT,
  PROJECT_CREATE: MENU_PARRENT.PROJECT + '/create',
  PROJECT_EDIT: `${MENU_PARRENT.PROJECT}/${MENU_PARRENT.EDIT}/:id`,
  PROJECT_DETAIL: `${MENU_PARRENT.PROJECT}/:id`,
  PROJECT_MODULES: `${MENU_PARRENT.PROJECT}/:id${MENU_PARRENT.MODULES}`,

  RESULTS: '/results',
  REPORT: '/report-template/:ids_project/:ids_subProject/:ids_module/:ids_location/:query_time/:start_time/:end_time/:processDown',
  CONTACT: '/helps',
  ANALYZE: '/analytics',
  INFORMATION: '/information',
  TUTORIAL: '/tutorial',

  SUB_PROJECT_CREATE: `${MENU_PARRENT.PROJECT}/:id/sub-project/create`,
  SUB_PROJECT_DETAIL: MENU_PARRENT.PROJECT + '/:id/sub-project/:id_sub_project',
  MODULE_TRAVELLING_PROJECT: MENU_PARRENT.PROJECT + '/:id/sub-project/:id_sub_project/tab-module/2',
  MODULE_MATERIAL_PROJECT: MENU_PARRENT.PROJECT + '/:id/sub-project/:id_sub_project/tab-module/3',
  MODULE_SHIPPING_PROJECT: MENU_PARRENT.PROJECT + '/:id/sub-project/:id_sub_project/tab-module/6',
  REPORT_PROJECT_DETAIL: MENU_PARRENT.PROJECT + '/report/:id',
  EQUIPMENT_LIST: MENU_PARRENT.EQUIPMENT,
  EQUIPMENT_CREATE: MENU_PARRENT.EQUIPMENT + '/create',
  REPORT_LIST: MENU_PARRENT.REPORT,
  EQUIPMENT_ANALYZE: MENU_PARRENT.ANALYZE + '/equipment',
  SPAREPARTS_ANALYZE: MENU_PARRENT.ANALYZE + '/spareparts',
  EOL_ANALYZE: MENU_PARRENT.ANALYZE + '/endoflife',
  MATERIALS_ANALYZE: MENU_PARRENT.ANALYZE + '/materials',
  SITEUSAGE_ANALYZE: MENU_PARRENT.ANALYZE + '/siteusage',
  MACHINES_ANALYZE: MENU_PARRENT.ANALYZE + '/machines',
  OPERATIONS_ANALYZE: MENU_PARRENT.ANALYZE + '/operation',
  TRAVELING_ANALYZE: MENU_PARRENT.ANALYZE + '/travelling',
  SHIPPING_ANALYZE: MENU_PARRENT.ANALYZE + '/shipping',
  ADMIN_LIST: MENU_PARRENT.ADMIN,
  COMPARISION: '/comparison',
  USAGE_REPORT: '/usage-report',
  CONNECT_MICROSOFT: '/connect/:providerName/redirect',
  SINGLE_PROJECT_REPORT: '/single-project-report/',
  COMPARISON_REPORT: '/project-comparison-report/',
  HIGH_LEVEL_RESULT: '/high-level-result/',
  CUSTOM_RESULTS: '/custom-results/',
};
