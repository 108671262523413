export enum REQUEST_METHOD {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}

export enum PAGE {
  PAGE_SIZE_10 = 10,
  PAGE_SIZE_15 = 15,
  PAGE_SIZE_20 = 20,
  PAGE_SIZE_25 = 25,
  PAGE_SIZE_30 = 30,
}
export const enum ROLE {
  ADMIN = 7,
  USER = 8,
  PROJECT_MANAGER = 6,
}

export const ROLE_PERMISSION = {
  NEED_CHECK_VISIBILITY: [ROLE.USER, ROLE.PROJECT_MANAGER],
  NEED_CHECK_MODIFY: [ROLE.USER, ROLE.PROJECT_MANAGER],
  NEED_APPROVED: [ROLE.USER, ROLE.PROJECT_MANAGER],
  CAN_APPROVED: [ROLE.ADMIN],
  CAN_SETTING_USER: [ROLE.ADMIN, ROLE.PROJECT_MANAGER],
  CAN_ACTIVE_TAB: [ROLE.ADMIN],
  CAN_MODIFY: [ROLE.ADMIN],
  CAN_SHARE: [ROLE.ADMIN, ROLE.PROJECT_MANAGER],
};

export const DATE_PATTERN = {
  YYYY_MM_DD: 'YYYY/MM/DD',
  YYYY_MM_DD_HH_mm_ss: 'YYYY/MM/DD HH:mm:ss',
  DD_MM_YYYY: 'DD/MM/YYYY',
  YYYY: 'YYYY',
  MM_YYYY: 'MM/YYYY',
};

export const NOTIFY_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
};

export const PAGINATION = {
  DEFAULT_PAGE: 1,
  DEFAULT_PAGE_SIZE: 10,
  PAGE_SIZE_OPTION: [10, 15, 20, 25, 30],
  DEFAULT_TOTAL_PAGE: 1,
};

export enum IMPACTS_TYPE {
  WASTE = 'Waste',
  WATER_DISPOSAL = 'Water disposal (non domestic activities)',
  WATER_WITHDRAWAL = 'Water withdrawal',
  ENERGY_USE = 'Energy use',
  OIL_AND_CHEMICALS = 'Oil & chemical spills',
  OIL_AND_CHEMICALS_OTHER_CHEMICALS = 'Other chemicals',
  UNIT_M3 = 'm3',
  UNIT_KL = 'kL',
  UNIT_CO2EQ = 'kg CO2e',
  UNIT_MWH = 'MWh',
  KWH = 'kWh',
  HVDC = 'HVDC',
  GPQSS = 'GPQSS',
}
export const DEFAULT_LIMIT_SIZE = 100;
export const MONTH = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export enum TYPE_FILE {
  IMAGE_JPG = 'jpg',
  IMAGE_JPEG = 'jpeg',
  PDF = 'pdf',
  DOC = 'doc',
  DOCX = 'docx',
  XLS = 'xls',
  XLS_EXCEL = 'xlsx',
  MS_WORD = 'doc',
  DOCUMENT = 'docx',
  XLSX = 'xlsx',
  CSV = 'csv',
  ZIP = 'zip',
  XLSM = 'xlsm',
}

export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

export enum TYPE_MODULES {
  EQUIPMENT = 'Equipment',
  SOLUTION = 'Solution',
  MODULES = 'Modules',
}

export const SELECT_TYPE_CALCULATION = {
  OVERALl_LOSSES: 'Overall losses',
  AVERAGE_CHARGING: 'Average charging',
  CHARGING_CYCLES: 'Charging cycles',
};

export const VALUES_ITEM_OPERATION_MODULE_DEFAULT = {
  LOSSES_RATE: 6,
  AVERAGE_LOADING: 46,
};

export const ORGANIZATIONAL = {
  ECO_SALES: 2,
};
export const DATA_FACTOR = [
  {
    key: 0,
    value: 'Global Warming Potential',
    label: 'Global Warming Potential',
  },
  {
    key: 1,
    value: 'Acidification',
    label: 'Acidification',
  },
  {
    key: 2,
    value: 'Eutrophication',
    label: 'Eutrophication',
  },
  {
    key: 3,
    value: 'Freshwater Aquatic Ecotoxicity',
    label: 'Freshwater Aquatic Ecotoxicity',
  },
  {
    key: 4,
    value: 'Marine Aquatic',
    label: 'Marine Aquatic',
  },
  {
    key: 5,
    value: 'Terrestric Ecotoxicity',
    label: 'Terrestric Ecotoxicity',
  },
  {
    key: 6,
    value: 'Human Toxicity',
    label: 'Human Toxicity',
  },

  {
    key: 7,
    value: 'Ozone Layer Depletion',
    label: 'Ozone Layer Depletion',
  },
  {
    key: 8,
    value: 'Photochemical Ozone Creation',
    label: 'Photochemical Ozone Creation',
  },
];

export const ROLE_ID = {
  ADMIN: 7,
  PROJECT_MANAGER: 6,
  USER: 8,
};

export const GAS_LEAKAGE = {
  SF_6: 'SF6',
  ECONIQ_GAS: 'EconiQ gas',
};
export const SCOPE = [
  {
    name: 'Scope 3',
    bg: 'rgb(255, 0, 38)',
  },
  {
    name: 'Scope 2',
    bg: 'rgb(45, 45, 45)',
  },
  {
    name: 'Scope 1',
    bg: 'rgb(189, 190, 191)',
  },
];
export const SCOPE_SORT = ['Scope 3', 'Scope 1', 'Scope 2'];
export const OPERATION_SOURCE = {
  LOCATION: 'location',
  ENERGY: 'source_of_energy',
};

export const TYPE_SOLUTION_PROJECT = {
  HdvcGeneration4: 'HVDC – Generation 4',
  HdvcGeneration5: 'HVDC – Generation 5',
  GridEXpandtm: 'Grid-eXpand™',
  GisSubstaion: 'GIS substation',
  GridEMotion: 'Grid-eMotion ®',
  FixedSeriesCompensation: 'Fixed Series Compensation',
  SFCLight: 'SFC Light',
  SVCLightV2250MVAR: 'SVC Light V2 250 MVAR',
  SVCLightV3300MVAR: 'SVC Light V3 300 MVAR',
};
