import { createContext, useState } from 'react';

interface IProjectContext {
  project: InfoDataProject | undefined;
  setProject: React.Dispatch<React.SetStateAction<InfoDataProject | undefined>>;
}

const ProjectContext = createContext<IProjectContext>({
  project: null as any,
  setProject: () => {},
});

function ProjectProvider({ children }: { children: React.ReactNode }) {
  const [project, setProject] = useState<InfoDataProject | undefined>();
  return <ProjectContext.Provider value={{ project, setProject }}>{children}</ProjectContext.Provider>;
}

export { ProjectContext, ProjectProvider };
