import moment from 'moment';

export const formatDate = (date: string, format: string) => {
  return moment.utc(date).local().format(format);
};

export const dayExpires = 7 * 24 * 60 * 60 * 1000;
export const dayExpires1Minute = 60 * 1000;

export const removeFileUploadExt = (fileName: string | null | undefined) => {
  return fileName?.replace(/\.[^/.]+$/, '');
};
