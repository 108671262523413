import { LogOut } from '@hitachivantara/uikit-react-icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from 'src/hooks';
import Logo from '../../assets/images/logo.svg';
import '../../styles/pages/header.scss';
import { ROUTES } from '../../utils/constants/routes';
import { ApiRequest } from 'src/api';
import { PATH } from 'src/services/Path';
import { NOTIFY_TYPE, REQUEST_METHOD } from 'src/utils/constants/enums';
import { OpenToast } from 'src/utils/helpers/function';
import { renderMessageID } from 'src/utils/helpers/untils';
import useStoreGlobal from 'src/hooks/useStoreGlobal';

const Header = () => {
  const wrapperRef: any = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuthContext();
  const storeGlobal = useStoreGlobal();

  const [isDrop, setIsDrop] = useState(false);
  const [avatarName, setAvatarName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastname, setLastname] = useState('');
  const [roleName, setRoleName] = useState('');
  const [showUser, setShowUser] = useState(false);

  useEffect(() => {
    if (auth?.userInfo?.user) {
      let firstName = auth.userInfo?.user?.firstname ? auth.userInfo?.user?.firstname : '';
      let lastname = auth.userInfo?.user?.lastname ? auth.userInfo?.user?.lastname : '';
      let roleName = auth.userInfo?.user?.role_name ? auth.userInfo?.user?.role_name : '';

      if (firstName && lastname) {
        setAvatarName(`${firstName.charAt(0)}${lastname.charAt(0)}`);
      }
      setFirstName(firstName);
      setLastname(lastname);
      setRoleName(roleName);
      setShowUser(true);
    } else {
      setShowUser(false);
    }
  }, [location.pathname, auth.userInfo]);

  useEffect(() => {
    if (
      location.pathname === ROUTES.LOGIN ||
      location.pathname === ROUTES.FORGOT_PASSWORD ||
      location.pathname.includes('reset-password') ||
      location.pathname.includes('check-token-reset-password')
    ) {
      setShowUser(false);
    } else {
      setShowUser(true);
    }
  }, [location]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !(wrapperRef.current as any).contains(event.target)) {
        setIsDrop(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const openDropLogout = () => {
    setIsDrop(true);
  };

  const logOut = useCallback(async () => {
    setIsDrop(false);
    storeGlobal.setIsLoading(true);
    // Call Api Logout
    const response = await ApiRequest(PATH.LOG_OUT, REQUEST_METHOD.POST, true);

    if (response?.isError) {
      OpenToast(
        {
          message: renderMessageID('MSE00075'),
        },
        NOTIFY_TYPE.ERROR,
      );
      storeGlobal.setIsLoading(false);
      return;
    }

    // Clear localStorage , context
    localStorage.clear();
    auth.setAuth && auth.setAuth({ ...auth, isAuth: false, userInfo: null });
    storeGlobal.setIsLoading(false);

    return navigate(ROUTES.LOGIN);
  }, []);

  return (
    <>
      <div className="container-header">
        <div className="inner">
          <div className="logo-link" onClick={() => navigate(ROUTES.HOME)}>
            <img src={Logo} alt="Hitachi" className="logo"></img>
            <span className="logo-brand">EcoSpace™ Sales</span>
          </div>
          {showUser && (
            <div className="action">
              <div className="container-header-avatar" onClick={openDropLogout}>
                <span>{avatarName}</span>
              </div>
            </div>
          )}
          {isDrop && (
            <div ref={wrapperRef} className="show__action blockDrop">
              <div className="show__action-drop">
                <div>
                  <p className="show__action-name">Username</p>
                </div>
                <div>
                  <p className="show__action-name-value" style={{ overflow: 'hidden' }}>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                      {roleName}, {firstName + ' ' + lastname}{' '}
                    </span>
                  </p>
                  <p className="show__action-name-value"></p>
                </div>
                <div className="show__action-btnLogout" onClick={logOut}>
                  <LogOut />
                  <p>Log out</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="container-header-opacity">
        <div className="inner">
          <div className="logo-link">
            <img src={Logo} alt="Hitachi" className="logo"></img>
            <span className="logo-brand">EcoSpace™ Build</span>
          </div>
          <div className="action"></div>
        </div>
      </div>
    </>
  );
};

export default Header;
